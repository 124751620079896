.carousel-item img {
  width: 100%;
}

.owl-carousel {
  touch-action: none;
}

.carousel-img {
  width: 100%;
  height: auto;
}

.product-slider {
  z-index: 100;
  position: relative;
  top: -28px;
}
.product-slide {
  margin: auto;
}

.slick-prev:before,
.slick-next:before {
  color: #7c7b7b !important;
}

/* mobile screen */
@media (min-width: 0) {
  .product-slider {
    top: 0;
  }
  .product-slide {
    max-height: 106px;
  }
}

/* landscape mobile */
@media (min-width: 576px) {
  .product-slide {
    max-height: 155px;
  }
}

/* tablets */
@media (min-width: 768px) {
  .product-slide {
    max-height: 155px;
  }
}

/* desktops */
@media (min-width: 992px) {
  .product-slider {
    top: -28px;
  }
  .product-slide {
    max-height: 300px;
  }
}

a.disabled-link {
  pointer-events: none;

}