.image-information {
  margin-bottom: 30px;
}

.image-block {
  display: inline-block;
}

.image-label {
  display: inline-block;
  margin-top: 10px;
}

.or-divider {
  display: inline-block;
  position: relative;
  bottom: 60px;
  margin-left: 50px;
  margin-right: 50px;
}

