.form-control {
    font-family: sans-serif;
    font-size: 14px;
    border-radius: 0;
    text-transform: uppercase;
}

#email {
    text-transform: none;
}

.form-control::placeholder {
    color: rgba(179,179,179,1.00);
}

/* .col {
    padding-left: 10px;
    padding-right: 10px;
} */

.state_select {
    all: unset;
    display: block;
    width: 100%;
    padding: 0.375rem 0.75rem;
    /* color: #495057; */
    color: #999;
    font-family: sans-serif;
    font-size: 14px;
    letter-spacing: 0px;
    text-transform: uppercase;
    height: 35px !important;
    background-color: #fff;
    background-clip: padding-box;
    border: 1px solid #ced4da;
    box-sizing: border-box;
    -webkit-transition: border-color 0.15s ease-in-out, -webkit-box-shadow 0.15s ease-in-out;
    transition: border-color 0.15s ease-in-out, -webkit-box-shadow 0.15s ease-in-out;
    -o-transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
    transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
    transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out, -webkit-box-shadow 0.15s ease-in-out;    
}

.default-state {
    color: grey;
}

.checkboxClass {
    font-family: sans-serif;
    font-size: 14px;
    letter-spacing: 0px;
    float: left;
}

.inner-card-header {
    border-top: 1px solid rgba(0, 0, 0, 0.125);
    border-top-left-radius: 0px;
    border-top-right-radius: 0px;
}

.order-details {
    padding: 0;
}

.order-table {
    text-align: left;
    border-top-style: hidden;
}

.payment-methods {
    color: black;
}

.payment-methods:hover {
    color: #f8449d;
}

#notes_label {
    font-size: 12px;
    font-style: italic;
}

#notes {
    text-transform: none;    
}

.button.pay {
    width: 220px;
    margin: 10px;
}

tfoot {
    background-color:#e9ecef;
    font-weight: bold;
}

.currency {
    text-align: right;
}