.map-outer {
  text-align: right;
  height: 500px;
  width: 100%;
}

.gmap-canvas {
  overflow: hidden;
  background: none !important;
  height: 500px;
  width: 100%;
}
