.footer-div {
  position: relative;
  color: white;
  background-image: linear-gradient(to right,#a8021f, #fa0f34, #a8021f);
  line-height: 25px;
  min-height: 50px;
  font-size: 13px;
}

.footer-links {
  background: #787878;
}

.footer-div a,
.footer-links-col h3,
.footer-links-col li a {
  color: white;
}
.footer-links-col h3 {
  font-size: 15px;
}
.footer-links-col li a {
  text-transform: lowercase;
  font-size: 13px;
}

.footer-links-col ul {
  list-style: none;
  padding-left: 0;
}
.footer-div a,
.footer-links-col li a {
  text-decoration: none;
}

.footer-icon {
  position: absolute;
  top: -213px;
  right: 0;
}

/* mobile screen */
@media (min-width: 0) {
  .footer-links {
    text-align: center;
  }
  .footer-links-col h3 {
    font-size: 16px;
  }
  .footer-links-col li a {
    font-size: 14px;
  }
}

/* landscape mobile */
@media (min-width: 768px) {
  .footer-div {
    line-height: 50px;
  }
}

/* tablets */
@media (min-width: 992px) {
  .footer-links {
    text-align: left;
  }
  .footer-links-col h3 {
    font-size: 15px;
  }
  .footer-links-col li a {
    font-size: 13px;
  }
}

li> a.disabled-link {
  pointer-events: none;
  color:silver
}