/* Nav bar background */
.navbar-bg {
  background: #fa0f34;
  background-image: linear-gradient(to right,#a8021f, #fa0f34, #a8021f);
}

/* Nav bar content area */
.navbar {
  width: 100%;
}

/* Nav bar text */
.navbar-dark .navbar-nav .nav-link
{
  color: white;
}

/* Dropdown menu background */
.dropdown-menu
{
  background-color: #ebe9e3;
  font-size: 0.9rem;
}

/* Dropdown item text */
.dropdown-menu > a
{
  color: black;
  text-decoration: none;
}

.navbar-cart {
  display: none;
}

.navbar-brand {
  display: none;
}

a.nav-link {
  text-align: center;
}
button.out-of-stock{
  color:grey
}


@media (max-width: 767px) {
  .navbar-brand {
    display: block;
  }

  .navbar-bg {
    background: #aaaaaa;
    background-image: linear-gradient(to right,#999999, #aaaaaa, #999999);
  }

  .navbar-cart {
    display: block;
  }

  a.nav-link {
    text-align: unset;
  }
}

