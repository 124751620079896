/* box category */
ul.box-category, ul.box-category ul {
	list-style: none;
	margin: 0;
	padding: 0;
	padding-left:20px;
}
/* ul.box-category h4{
	color:#fd1a1a;
	font-weight:normal;
	font-size:17px;
	margin-bottom:5px;
	margin-top:5px;
} */
ul.box-category h4{
	font-weight:normal;
	font-size: 14px;
	margin:0px;
	color:#504E4E;
	cursor:pointer;
}
ul.box-category a{
	color:#504E4E;
	text-decoration: none;
}
ul.box-category h4:hover{
	color:#fd1a1a;
}
ul.box-category h4:focus{
	color:#fd1a1a;
}
ul.box-category a:hover{
	color:#fd1a1a;
}
ul.box-category a:focus{
	color:#fd1a1a;
}
ul.box-category ul{
	padding: 0;
	margin:0;
}
ul.box-category > li:first-child {
	padding: 0px 8px 8px 0px;
}
ul.box-category > li {
	padding: 8px 8px 8px 0px;
}
ul.box-category > li + li {
}
ul.box-category > li > a {
	text-decoration: none;
	color: #444;
}
ul.box-category > li > a:hover{
	color:#ccc;
}

/* Product */
.product-info {
    margin-top: 20px;
	margin-bottom: 20px;
	padding-left:25px;
}

#product-title{
	width: 214px;
	/* padding-left:20px; */
	color: #fd1a1a;
	font-size: 20px;
	margin-bottom:25px;
	margin-top:40px;
}

#product-digital-book{
	background: linear-gradient(0.125turn, #a7021f 60%, #fe1035);
	padding: 4px 8px;
	padding-top: 1em;
	color: white;
	margin-top: 5px;
	font-size: 3em;
    line-height: 1.25em;
}

#custom-link{
	margin-top:50px;
	margin-bottom:10px;
}

@media (max-width: 768px) {
	#sidebar {
		display: none;
	}
}

.button.checkout-disabled {
    background: gray;
}

li> a.disabled-link {
	pointer-events: none;
	color:gray
  }

li> h4.disabled-link {
	color:gray
  }